.topnav {
  overflow: hidden;
  background-color: black;
  color:  white;
}

/* Style the topnav links */
.topNavLink {
  float: left;
  display: block;
  color: white;
  font-size: 3vw;
  text-align: center;
  padding: 2vw 4vw;
  text-decoration: none;
}




.topNavLink:hover {
  color: lightblue;
}